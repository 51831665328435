const moment = require('moment');
import Swiper from 'swiper/swiper-bundle.js';

/**
	* 単純なサンプル
	* moment.jsを使って現在日付をconsole出力するだけ
	*/
$(function () {
	console.log(moment().format("YYYY-MM-DD"));
	const swiper = new Swiper('.toppage__slide .swiper', {
		loop: true,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});
});
